import {UnleashType} from "@utils/data";

type DataType = {
  unleashData: Array<UnleashType>
  title: string
  description: string
  capText?: string
}

const Unleash = ({unleashData, title, description, capText}: DataType) => {
  return (
    <div className="unleash-holder">
      <div className="uni-width">
        {capText && <p className="cap-title six-12 text-tiffany">{capText}</p>}

        <p className="mt-20 header-width text-brand-blue heading-4 inter-display">
          {title}
        </p>

        <p className="text-secondary header-width mt-20 body-lg-regular">
          {description}
        </p>

        <div className="unleash-row mt-48">
          {unleashData.map((unleash) => {
            return (
              <div className="unleash card-shadow" key={unleash.title}>
                <img
                  src={unleash.icon}
                  alt="fintech icon"
                  className="fintech-image"
                />

                <p className="unleash-cap six-12 mt-32"> {unleash.cap} </p>

                <p className="text-primary mt-12 flex-start heading-6 inter-display">
                  {unleash.title}
                </p>

                <p className="text-secondary mt-16 flex-start  body-lg-regular">
                  {unleash.description}
                </p>

                <a
                  className="web-learn text-link six-16 mt-32 flex-start"
                  href={unleash.url}
                  target={unleash?.internal ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  <span className="mr-8">{unleash.anchorText}</span>{" "}
                  <img
                    src="/assets/icons/green-arrow.svg"
                    width={13.33}
                    height={10}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export {Unleash};

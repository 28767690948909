type BackgroundLinesProp = {
  image?: string
  message?: string
  name?: string
  role?: string
}

const BackgroundLines = ({image, message, name, role}: BackgroundLinesProp) => {
  return (
    <div className="background-lines-holder">
      <div className="background-lines-inner uni-width flex-center">
        <img
          className="background-lines-image "
          src={image ?? "/assets/images/Company.svg"}
          alt="background "
        />

        <div className="background-lines-info">
          <p className="text-white blockquote heading-4 inter-display">
            {message ??
              `Lendsqr has significantly expedited our entry into the difficult but
              large retail lending market. Their solution not only shortened the
              time to market but also at a significantly reduced cost.`}
          </p>

          <p className="text-white mt-24 body-lg-bold">
            {name ?? "Abubakar Suleiman"}
          </p>

          <p className="ceo-title mt-2 body-md-regular">
            {role ?? "CEO, Sterling Bank (SnapCash)"}
          </p>
        </div>
      </div>
    </div>
  );
};

export {BackgroundLines};

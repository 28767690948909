import type {NextPage} from "next";
import Head from "next/head";
import Script from "next/script";
import HomePage from "./home";

const Home: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Loan management software | Lending software | Lendsqr</title>
        <meta
          name="description"
          content="Explore Lendsqr's loan management software for easy and efficient lending. Streamline your lending operations with our end-to-end solutions."
        />
        <meta
          name="og:description"
          content="Explore Lendsqr's loan management software for easy and efficient lending. Streamline your lending operations with our end-to-end solutions."
        />
        <meta
          name="og:title"
          content="Loan management software | Lending software | Lendsqr"
        />

        <meta property="og:type" content="website" />

        <meta property="og:url" content="https://lendsqr.com/" />

        <meta name="og:image" content="https://lendsqr.com/favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T9FNFG7');
      `}
        </Script>
      </Head>

      <HomePage />
    </div>
  );
};

export default Home;

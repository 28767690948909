import {TrustUs} from "@components/templates/home/TrustUs";
import {MainLayout} from "@layouts/MainLayout";
import {NextPage} from "next";
import {Hero} from "@components/templates/home/hero";
import {Capabilities} from "@components/templates/home/Capabilites";
import {Masterd} from "@components/templates/home/Masterd";
import {BackgroundLines} from "@components/templates/home/BackgroundLines";
import {Unleash} from "@components/templates/home/Unleash";
import {LendersLove} from "@components/templates/home/LendersLove";
import {Word} from "@components/templates/home/Word";
import {Tailored} from "@components/templates/home/Tailored";
import {Adjutor} from "@components/templates/home/Adjutor";
import {LatestBlog} from "@components/templates/lenders/LatestBlog";
import {unleashData} from "@utils/data";

const HomePage: NextPage = () => {
  return (
    <MainLayout
      title="Loan Management Software for the Smartest Lenders, Banks, and Fintechs"
      description="Start and scale your loan business with Lendsqr loan management system. Get the best features to position you as a market leader and make your customers happy!"
      isHome
    >
      <Hero
        title="The best loan management system for smart lenders"
        description="Lending is profitable but also difficult but not with Lendsqr's loan management software! Start, launch and scale your loan business easily across multiple channels and make smart decisions all at a fraction of the cost!"
        heroImage="assets/images/HeroIllustration.svg"
        primaryText="Get started free "
        newPost={true}
        heroPosition="flex-end"
      />

      <TrustUs />

      <Capabilities />

      <BackgroundLines />
      <Masterd />

      <Adjutor />

      <Unleash
        unleashData={unleashData}
        title="Empower your loan business with Lendsqr"
        capText="everything you need to keep going"
        description="Lendsqr is the only powerhouse you need to drive your loan business. Our end-to-end digital lending software integrates all the features you need to run your loan operations smoothly."
      />

      <LendersLove />

      <Word />

      <Tailored />

      <LatestBlog />

      {/* <Hero />
        <Steps />
        <TrustUs />
        <About />
        <Adjutor />
        <Channels />
        <Industries />
        <Explore />
        <PricingList setShowModal={setShowModal} setPlan={setPlan} isHome />

        {showModal && (
          <PricingModal
            title="Request Pricing"
            subText="Please provide us with more details so we could help with your lending requirements"
            closingText="Our response time with a tailored solution for you will be between"
            setOpenModal={setShowModal}
            plan={plan}
          />
        )} */}
    </MainLayout>
  );
};

export default HomePage;

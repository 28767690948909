const Adjutor = () => {
  return (
    <section className="flex relative bg-[rgb(9,30,66)] text-white  min-[950px]:pr-0">
      <div className="grid xls:grid-cols-2 w-[90%]  mx-auto  max-w-[1216px] ">
        <div className="flex flex-col gap-y-5 pt-12   w-full lg:self-center">
          <h2 className="uppercase text-tiffany font-semibold text-[12px] tracking-[2px]">
            Built to empower lenders
          </h2>
          <h3 className="heading-4 inter-display">
            Lending APIs to power your loan business at scale
          </h3>
          <p className="body-lg-regular text-[rgba(255,255,255,0.7)]">
            Turbocharge your loan business with Adjutor lending APIs from
            Lendsqr. With Adjutor, you get assured loan repayments with our
            direct debit APIs; you get assurance of quality borrowers when you
            use our Karma blacklist APIs; and even more.
          </p>
          <div className="flex flex-wrap gap-[10px] pb-4">
            <a href="https://adjutor.io/" className="btn btn-primary">
              Learn more
            </a>
            <a
              href=" https://docs.adjutor.io/"
              className="btn border border-[rgba(255,255,255,0.12)]"
            >
              Read API documentation
            </a>
          </div>
        </div>
        <div className="py-[39px] ">
          <img
            src="/assets/images/adjutor.svg"
            alt="Adjutor Dashboard"
            className="w-full  xls:relative object-cover xls:-right-12 xl:-right-16 "
            height="500"
            width="200"
          />
        </div>
      </div>
    </section>
  );
};
// xl:w-[732px] xl:h-[552px]
export {Adjutor};

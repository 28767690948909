import {tailoredData} from "@utils/data";

const Tailored = () => {
  return (
    <div className="tailored-holder">
      <div className="tailored-inner uni-width">
        <p className="text-brand-blue mb-48 header-width heading-4 inter-display">
          Tailored solutions for your industry
        </p>

        <div className="tailored-scroll-holder">
          <div className="tailored-scroll">
            {tailoredData.map((tailored) => {
              return (
                <div className="tailored-cell" key={tailored.title}>
                  <a href={tailored?.url} target="_blank" rel="noreferrer">
                    <div
                      className="tailored"
                      style={{backgroundColor: tailored.color}}
                    >
                      <p className="text-primary mb-12 heading-6 inter-display">
                        {tailored.title}
                      </p>
                      <p className="text-secondary four-18">
                        {tailored.description}
                      </p>

                      <img
                        src="/assets/icons/tailored-hover.svg"
                        alt="arrow button"
                        className="tailored-hover"
                      />

                      <img
                        src="/assets/icons/tailored-hover.svg"
                        alt="arrow button"
                        className="tailored-hover-hide"
                      />
                    </div>

                    <img
                      src={tailored.icon}
                      alt={tailored.title}
                      className="tailored-image"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export {Tailored};
